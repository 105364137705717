import React, { useEffect, useState } from "react";
import "./SideBar.css";
import { useIntl } from "react-intl";
import { useAuth } from "../auth/components/AuthInit";
import { toAbsoluteUrl } from "../../utils/CommonHelper";
import { callMemberBalance } from "../home/redux/HomeAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function SideBar({ isSidebarOpen, onClose }) {
  const intl = useIntl();
  const { logout } = useAuth();
  const { auth } = useAuth();
  const dispatch = useDispatch();
  const [comingDate, setComingDate] = useState();
  const [comingDay, setComingDay] = useState();
  const [categoryIndex, setCategoryIndex] = useState(1);
  const navigate = useNavigate();

  const handleLogout = async () => {
    if (sessionStorage.getItem("platform") === "mobile") {
      localStorage.clear();
      sessionStorage.clear();
    } else {
      await logout();
    }
  };
  useEffect(() => {
    const queryParams = {
      action: "memberbalance",
    };
    dispatch(callMemberBalance(queryParams)).then((resp) => {
      resp = resp.data;
      setComingDate(resp.nextcomingdate);
      setComingDay(resp.nextcomingday);
    });
  }, []);
  const handleCategoryClick = (index) => {
    setCategoryIndex(index);
    navigate("/home", { state: { categoryIndex: index } });
    sessionStorage.setItem("categoryIndex", index);
    onClose();
  };
  return (
    <>
      <div className={`sidebar-container ${isSidebarOpen ? "open" : ""}`}>
        <aside id="sidebar" className="sidebar">
          <div className="d-flex align-items-center" style={{ margin: "5px" }}>
            <img
              src={toAbsoluteUrl("/image/slideBar/person.png")}
              alt="Profile"
              className=" mx-2"
              style={{ width: "40px", height: "40px", borderRadius: "50%" }}
            />
            <span className="ms-2 text-uppercase">{auth.username}</span>
          </div>
          <div className="align-items-center ps-1">
            <span
              className="ps-1"
              style={{ fontSize: "12px", fontStyle: "italic" }}
            >
              {intl.formatMessage({ id: "nextComing" })}&nbsp;{":"}
            </span>
          </div>
          <div>
            <span className="ps-2" style={{ fontSize: "12px" }}>
              {comingDate} ({comingDay})
            </span>
          </div>
          <hr />
          <ul className="sidebar-nav" id="sidebar-nav">
            <li className="nav-item">
              <div
                onClick={() => handleCategoryClick(7)}
                style={{
                  backgroundColor: categoryIndex === 7 ? "#f5be09" : "",
                  borderRadius: "5px",
                  color: categoryIndex === 7 ? "black" : "",
                }}
              >
                <img
                  src={toAbsoluteUrl("/image/slideBar/1.png")}
                  alt="wallet"
                  className="my-2 mx-2"
                  style={{ width: "30px", height: "30px" }}
                />
                <span>{intl.formatMessage({ id: "depositOrWithdraw" })}</span>
              </div>
            </li>
            <li className="nav-item">
              <div
                onClick={() => handleCategoryClick(1)}
                style={{
                  backgroundColor: categoryIndex === 1 ? "#f5be09" : "",
                  borderRadius: "5px",
                  color: categoryIndex === 1 ? "black" : "",
                }}
              >
                <img
                  src={toAbsoluteUrl("/image/slideBar/betnow.png")}
                  alt="bet"
                  className="my-2 mx-2"
                  style={{ width: "30px", height: "30px" }}
                />
                <span>{intl.formatMessage({ id: "betNow" })}</span>
              </div>
            </li>

            <li className="nav-item">
              <div
                onClick={() => handleCategoryClick(2)}
                style={{
                  backgroundColor: categoryIndex === 2 ? "#f5be09" : "",
                  borderRadius: "5px",
                  color: categoryIndex === 2 ? "black" : "",
                }}
              >
                <img
                  src={toAbsoluteUrl("/image/slideBar/history.png")}
                  alt="slip"
                  className="my-2 mx-2"
                  style={{ width: "30px", height: "30px" }}
                />
                <span>{intl.formatMessage({ id: "slip" })}</span>
              </div>
            </li>
            <li className="nav-item">
              <div
                onClick={() => handleCategoryClick(0)}
                style={{
                  backgroundColor: categoryIndex === 0 ? "#f5be09" : "",
                  borderRadius: "5px",
                  color: categoryIndex === 0 ? "black" : "",
                }}
              >
                <img
                  src={toAbsoluteUrl("/image/slideBar/result.png")}
                  alt="result"
                  className="my-2 mx-2"
                  style={{ width: "30px", height: "30px" }}
                />
                <span>{intl.formatMessage({ id: "result" })}</span>
              </div>
            </li>

            <li className="nav-item">
              <div
                onClick={() => handleCategoryClick(4)}
                style={{
                  backgroundColor: categoryIndex === 4 ? "#f5be09" : "",
                  borderRadius: "5px",
                  color: categoryIndex === 4 ? "black" : "",
                }}
              >
                <img
                  src={toAbsoluteUrl("/image/slideBar/report.png")}
                  alt="report"
                  className="my-2 mx-2"
                  style={{ width: "30px", height: "30px" }}
                />
                <span>{intl.formatMessage({ id: "report" })}</span>
              </div>
            </li>

            <li className="nav-item">
              <div
                onClick={() => handleCategoryClick(8)}
                style={{
                  backgroundColor: categoryIndex === 8 ? "#f5be09" : "",
                  borderRadius: "5px",
                  color: categoryIndex === 8 ? "black" : "",
                }}
              >
                <img
                  src={toAbsoluteUrl("/image/slideBar/group.png")}
                  alt="group"
                  className="my-2 mx-2"
                  style={{ width: "30px", height: "30px" }}
                />
                <span>{intl.formatMessage({ id: "group" })}</span>
              </div>
            </li>
            <li className="nav-item">
              <div
                onClick={() => handleCategoryClick(3)}
                style={{
                  backgroundColor: categoryIndex === 3 ? "#f5be09" : "",
                  borderRadius: "5px",
                  color: categoryIndex === 3 ? "black" : "",
                }}
              >
                <img
                  src={toAbsoluteUrl("/image/slideBar/prize.png")}
                  alt="payout"
                  className="my-2 mx-2"
                  style={{ width: "30px", height: "30px" }}
                />
                <span>{intl.formatMessage({ id: "lotteryPayout" })}</span>
              </div>
            </li>
            <li className="nav-item">
              <div
                onClick={() => handleCategoryClick(5)}
                style={{
                  backgroundColor: categoryIndex === 5 ? "#f5be09" : "",
                  borderRadius: "5px",
                  color: categoryIndex === 5 ? "black" : "",
                }}
              >
                <img
                  src={toAbsoluteUrl("/image/slideBar/chat.png")}
                  alt="chat"
                  className="my-2 mx-2"
                  style={{ width: "30px", height: "30px" }}
                />
                <span>{intl.formatMessage({ id: "chat" })}</span>
              </div>
            </li>

            <li className="nav-item">
              <div
                onClick={() => handleCategoryClick(6)}
                style={{
                  backgroundColor: categoryIndex === 6 ? "#f5be09" : "",
                  borderRadius: "5px",
                  color: categoryIndex === 6 ? "black" : "",
                }}
              >
                <img
                  src={toAbsoluteUrl("/image/slideBar/profile.png")}
                  alt="profile"
                  className="my-2 mx-2"
                  style={{ width: "30px", height: "30px" }}
                />
                <span>{intl.formatMessage({ id: "profile" })}</span>
              </div>
            </li>

            <li className="nav-item">
              <div
                onClick={() => handleCategoryClick(9)}
                style={{
                  backgroundColor: categoryIndex === 9 ? "#f5be09" : "",
                  borderRadius: "5px",
                  color: categoryIndex === 9 ? "black" : "",
                }}
              >
                <img
                  src={toAbsoluteUrl("/image/slideBar/tutorial.png")}
                  alt="tutorial"
                  className="my-2 mx-2"
                  style={{ width: "30px", height: "30px" }}
                />
                <span>{intl.formatMessage({ id: "tutorial" })}</span>
              </div>
            </li>

            <li className="nav-item">
              <div className="nav-logout" onClick={handleLogout}>
                <i className="bi bi-box-arrow-left"></i>
                <span>{intl.formatMessage({ id: "logout" })}</span>
              </div>
            </li>
          </ul>
        </aside>
      </div>
      {isSidebarOpen && <div className="backdrop open" onClick={onClose}></div>}
    </>
  );
}
