import { useEffect, useRef, useState } from "react";
import { IoMdRefresh } from "react-icons/io";
import { BsFillPersonFill } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { useAuth } from "../../auth/components/AuthInit";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../utils/CommonHelper";
import * as actions from "../redux/HomeAction";
import Skeleton from "react-loading-skeleton";
import { LotteryResultListing } from "../../lottery/component/lotteryResult/LotteryResultListing";
import { LotteryBetDialog } from "../../lottery/component/lotteryBet/LotteryBetDialog";
import { LotteryPayout } from "../../lottery/component/lotteryPayout/LotteryPayout";
import { LotteryHistory } from "../../lottery/component/lotteryHistory/LotteryHistory";
import { Wallet } from "../../wallet/component/Wallet";
import { Personal } from "../../profile/component/Profile";
import { Chat } from "../../chat/components/Chat";
import { Report } from "../../report/component/Report";
import { Group } from "../../group/components/Group";
import { Tutorial } from "../../tutorial/component/Tutorial";
import { LotteryOnHold } from "../../lottery/component/lotteryOnHold/LotteryOnHold";
import SideBar from "../../slideBar/SlideBar";
// import { LotteryBetDialog } from "./BetDialog";
// import { LotteryResultListing } from "./Result";

export const Home = ({}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [categoryIndex, setCategoryIndex] = useState(1);
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const homeState = useSelector(({ home }) => home, shallowEqual);
  const [isLoading, setIsLoading] = useState(false);
  const language = localStorage.getItem("locale") || "en";
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const categoryMapping = {
    0: "result",
    1: "betNow",
    2: "slip",
    3: "lotteryPayout",
    4: "report",
    8: "group",
    5: "chat",
    6: "profile",
    9: "tutorial",
    7: "wallet",
  };
  const handleWalletClick = () => {
    // navigate("/setting/deposit", { state: { backTo: "/home" } });
  };
  useEffect(() => {
    const category = location.state?.categoryIndex;
    const index = sessionStorage.getItem("categoryIndex");
    if (`${category}` === `${index}`) {
      setCategoryIndex(category);
    } else {
      setCategoryIndex(1);
    }
  }, [location.state]);

  useEffect(() => {
    const queryParams = {
      action: "memberbalance",
    };
    dispatch(actions.callMemberBalance(queryParams));
  }, []);

  const handleRefreshClick = () => {
    setIsLoading(true);
    const queryParams = {
      action: "memberbalance",
    };
    dispatch(actions.callMemberBalance(queryParams));
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };
  const handleCategoryClick = (index) => {
    setCategoryIndex(index);
    navigate("/home", { state: { categoryIndex: index } });
    sessionStorage.setItem("categoryIndex", index);
  };
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  return (
    <div>
      <div
        className="mx-2 d-flex justify-content-between py-2"
        style={{
          borderBottom: "1px solid grey",
        }}
      >
        <i
          className="bi bi-list"
          onClick={toggleSidebar}
          style={{ cursor: "pointer" }}
        ></i>

        <div>{intl.formatMessage({ id: categoryMapping[categoryIndex] })}</div>
        <img
          src={toAbsoluteUrl("/image/logo/logo1.png")}
          alt="Logo"
          style={{ width: "30px", height: "auto" }}
        />

        {homeState && homeState.memberBalance && (
          <>
            <div className="align-items-center ps-1">
              <span className="ps-1" onClick={() => handleCategoryClick(7)}>
                <>
                  {homeState.memberBalance.currencycode}&nbsp;{":"}&nbsp;
                  {isLoading ? "-.--" : homeState.memberBalance.balance}
                </>
              </span>
              {!isLoading ? (
                <i
                  className="bi bi-arrow-clockwise mx-2"
                  onClick={() => handleRefreshClick()}
                />
              ) : (
                <i className="bi bi-arrow-clockwise mx-2" />
              )}
            </div>
          </>
        )}
      </div>

      <div className="d-flex mx-1 mt-1">
        <SideBar isSidebarOpen={isSidebarOpen} onClose={toggleSidebar} />
        <div className="col-12 ">
          {categoryIndex === 0 ? (
            <>
              <LotteryResultListing />
              {/* <Personal /> */}
            </>
          ) : categoryIndex === 1 ? (
            <>
              <LotteryBetDialog />
            </>
          ) : categoryIndex === 2 ? (
            <>
              <LotteryHistory />
            </>
          ) : categoryIndex === 3 ? (
            <>
              <LotteryPayout />
            </>
          ) : categoryIndex === 4 ? (
            <>
              <Report />
            </>
          ) : categoryIndex === 5 ? (
            <>
              <Chat />
            </>
          ) : categoryIndex === 6 ? (
            <>
              <Personal />
            </>
          ) : categoryIndex === 7 ? (
            <>
              <Wallet />
            </>
          ) : categoryIndex === 8 ? (
            <>
              <Group />
            </>
          ) : categoryIndex === 9 ? (
            <>
              <Tutorial />
            </>
          ) : categoryIndex === 10 ? (
            <>
              <LotteryOnHold />
            </>
          ) : (
            <>
              <LotteryPayout />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
